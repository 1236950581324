// Override default variables before the import
// $body-bg: #000;

.profile-pic {
    width: 100%;
}

.site-border {
    padding: 0 20px;
    .header-inner {
        margin-bottom: 10px;
        img {
            width:300px;
            margin:auto;
        }
    }
}

.stars-area {
    font-size: 3rem;
}

.beer-info {
    h5 {
        font-size: 1.8rem;
    }
    h6 {
        font-size: 1.2rem;
    }
    padding-bottom: 10px;
}

.marketing {
    h3 {
        font-size: 1.2rem;
    }
    margin-top: 30px;
}

.note-text {
    font-size: 0.9rem;  
}


.username {
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
}
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';